import {
  Directive,
  inject,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  Type,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmDictionary } from 'common-module/core/types';

import { ProvidedProvider } from './provided.provider';

@Directive()
export abstract class ProvidedComponent<
    Config extends CrmDictionary,
    Data extends CrmDictionary,
    Provider extends ProvidedProvider<Config, Data>,
  >
  implements OnInit, OnDestroy
{
  @Input() providerToken?: Type<Provider> | InjectionToken<Provider>;
  @Input() provider!: Provider;

  readonly injector = inject(Injector);
  readonly route = inject(ActivatedRoute, { optional: true });

  loading!: Signal<boolean>;
  config!: Signal<Config>;
  data!: Signal<Data>;

  ngOnInit() {
    this.setupProvider();
  }

  ngOnDestroy() {
    this.provider.onDestroy();
  }

  protected setupProvider() {
    if (this.route && !this.providerToken) {
      this.providerToken = this.route.snapshot.data['providerToken'];
    }

    if (!this.provider && this.providerToken) {
      this.provider = this.injector.get(this.providerToken);
    }

    this.initProvider();
  }

  protected initProvider() {
    if (!this.provider) {
      throw new Error(`Undefined provider for ${this.constructor.name}`);
    }

    this.loading = this.provider.loading;
    this.config = this.provider.config;
    this.data = this.provider.data;

    this.provider.init({ route: this.route }).subscribe();
  }
}
